import { createRouter, createWebHistory } from 'vue-router';
import payments from './payments';
import goikoiList from './goikoi';
import ulinkList from './ulink';

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    ...ulinkList,
    ...goikoiList,
    ...payments,
    {
      path: '/',
      name: 'home',
      component: () => import('../views/home/HomeView.vue'),
    },
    {
      path: '/gk/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/home/AboutView.vue'),
      alias: ['/about'],
    },
    {
      path: '/gk/reset-password',
      name: 'resetPassword',
      component: () => import('../views/password/ResetPassword.vue'),
    },
    {
      path: '/gk/verify-email',
      name: 'verifyEmail',
      component: () => import('../views/verify-email/verifyEmail.vue'),
    },
    {
      path: '/gk/deactivate-account',
      name: 'DeactivateAccount',
      component: () => import('../views/deactivate-account/DeactivateAccount.vue'),
    },
    {
      path: '/gk/dashboard/profile',
      name: 'DashboardProfile',
      component: () => import('../views/dashboard/DashboardProfile.vue'),
    },
    {
      path: '/gk/terms-of-use',
      name: 'TermsOfUse',
      component: () => import('../views/home/TermsOfUseView.vue'),
      alias: ['/terms-of-use'],
    },
    {
      path: '/gk/privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import('../views/home/PrivacyPolicyView.vue'),
      alias: ['/privacy-policy'],
    },
    {
      path: '/gk/learning/tips',
      name: 'learningTips',
      // component: () => import('../views/NotFound.vue'),
      component: () => import('../views/learning-tips/RichPage.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('../views/NotFound.vue'),
    },
    {
      path: '/review/index',
      name: 'review',
      component: () => import('../views/TheReview.vue'),
    },
    {
      path: '/:pathMatch(.*)',
      component: () => import('../views/NotFound.vue'),
    },
  ],
});

export default router;
